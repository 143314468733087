import "./projectList.css";
import Project from "../project/Project";
import { projects } from "../../data";

const ProjectList = () => {
  return (
    <div className="pl">
      <div className="pl-text">
        <h1 className="pl-title">Projects</h1>
        <p className="pl-desc">
          Some projects I have worked on in the past includes web development
          and data analysis projects.
        </p>
      </div>
      <div className="pl-list">
        {projects.map((item) => (
          <Project
            key={item.id}
            pImg={item.pImg}
            pLink={item.pLink}
            pType={item.pType}
            pTitle={item.pTitle}
            pTech={item.pTech}
            pInfo={item.pInfo}
            pLearn={item.pLearn}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
