import { Intro } from "./components/intro/Intro";
import ProjectList from "./components/projectList/ProjectList";
import Contact from "./components/contact/Contact";

function App() {
  return (
    <div>
      <Intro />
      <ProjectList />
      <Contact />
    </div>
  );
}

export default App;
