import { useRef, useState } from "react";
import "./contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const emailErrorRegex = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e8l02ju",
        "template_5s06h7m",
        formRef.current,
        "2IOObHUNobmNP8-63"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="c">
      <h1 className="c-title">Get in touch</h1>
      <p className="c-subtitle">
        Curious about a project or just want to chat? Get in touch and let's
        talk!
      </p>

      <form ref={formRef} onSubmit={handleFormSubmit}>
        {done && (
          <div className="submit-text">
            Info sent! I will get in touch with you as soon as possible. Talk to
            you soon!
          </div>
        )}
        <input type="text" placeholder="Name" name="user_name" required />
        <input type="text" placeholder="Subject" name="user_subject" required />
        <input
          type="email"
          placeholder="Email"
          name="user_email"
          required
          pattern={emailErrorRegex}
          onInvalid={(e) =>
            e.target.setCustomValidity(
              "This field is required and must be a valid email address."
            )
          }
          onInput={(e) => e.target.setCustomValidity("")}
        />
        <textarea rows="5" placeholder="Message" name="user_message" required />
        <button>Submit</button>
      </form>
    </div>
  );
};

export default Contact;
