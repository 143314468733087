import { useState } from "react";
import "./project.css";
import Modal from "../modal/Modal";

const Project = ({ pImg, pLink, pType, pTitle, pTech, pInfo, pLearn }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="p" onClick={() => setIsOpen(true)}>
        {pType === "web" && (
          <div className="p-browser">
            <div className="p-circle-wrapper">
              <div className="p-circle p-circle-red"></div>
              <div className="p-circle p-circle-orange"></div>
              <div className="p-circle p-circle-green"></div>
            </div>
          </div>
        )}
        <img src={pImg} alt="" className="p-img" />
        {/* <button onClick={() => setIsOpen(true)}>click</button> */}
        {/* {isOpen && <Modal setIsOpen={setIsOpen} info={info} />} */}

        {/* <a href={link} target="_blank" rel="noreferrer">
        <img src={img} alt="" className="p-img" />
      </a> */}
      </div>
      {isOpen && (
        <Modal
          setIsOpen={setIsOpen}
          pLink={pLink}
          pTitle={pTitle}
          pTech={pTech}
          pInfo={pInfo}
          pLearn={pLearn}
        />
      )}
    </>
  );
};

export default Project;
