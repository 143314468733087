import "./modal.css";

const Modal = ({ setIsOpen, pLink, pTitle, pTech, pInfo, pLearn }) => {
  return (
    <div className="modal">
      <div className="modal-overlay" onClick={() => setIsOpen(false)} />
      <div className="modal-content">
        <h2 className="text-center">{pTitle}</h2>
        <p className="text-center">
          👉{" "}
          <a href={pLink} target="_blank" rel="noreferrer">
            {" "}
            Check it out{" "}
          </a>{" "}
          👈
        </p>
        <br />
        <p className="content-header">Tech used</p>
        <p>{pTech}</p>
        <br />
        <p className="content-header">What its about</p>
        <p>{pInfo}</p>
        <br />
        <p className="content-header">Takeaways</p>
        <p>{pLearn}</p>
        <button
          title="close"
          className="modal-close"
          onClick={() => setIsOpen(false)}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
