export const projects = [
  {
    id: 1,
    pImg: require("./img/Awwdayeveryday.PNG"),
    pLink: "https://awwdayeveryday.netlify.app/",
    pType: "web",
    pTitle: "AwwDayEveryDay",
    pTech: "ReactJS",
    pInfo:
      "Awwdayeveryday is a TikTok-styled web app featuring only animal videos from Reddit. There are a ton of animal videos from different subreddits. However, there was no efficient way of viewing these videos. On the other hand, TikTok offers an efficient and resistant-free approach to watching videos consecutively. Rather than watching random videos and trends on TikTok, why not watch animal videos instead?",
    pLearn:
      "While this project was pure fun, I had multiple takeaways. I learned how to work with the Reddit API, HTML video player, and CSS snap scroll, among others. The platform was initially developed to be used as a Progressive Web App (PWA). However, performance issues arose both in the browser and as a PWA: the app would lag (and even crash) after scrolling several videos.",
  },
  {
    id: 2,
    pImg: require("./img/Safetoilet.PNG"),
    pLink: "https://peaceful-jennings-8aa832.netlify.app/",
    pType: "web",
    pTitle: "Safe Toilet",
    pTech: "ReactJS",
    pInfo:
      "Safe Toilet is a web app that features a list of safe restroom access for transgender, intersex, and gender-nonconforming individuals. Data for these restrooms are provided by Refuge Restrooms through their public API. The platform would request the user's location data and display a list of the nearest restrooms.",
    pLearn:
      "This project was an attempt at learning to call and use data from an API, but I also learned how to request location data from the user. This app was also designed to function as a Progressive Web App that can be installed on mobile devices.",
  },
  {
    id: 3,
    pImg: require("./img/Learnmalay.PNG"),
    pLink: "https://public-canvas.surge.sh/",
    pType: "web",
    pTitle: "Learn Malay",
    pTech: "VueJS (CDN), Python",
    pInfo:
      "Learn Malay is a web app developed to aid me in learning the Malay language. Instead of rote memorization of Malay words and their definition, I thought it would be more efficient to learn and memorize words through images. The app is designed game-like so to make the learning process more enjoyable. Users would choose the English word that corresponds to the Malay word shown. A short text (caption) that includes the Malay word would also be shown to demonstrate how the word is used in context.",
    pLearn:
      "The app uses COCO image dataset (since the entire dataset is too large, only val_image_annotations2017.json was used) for the images and captions. Spacy was used to choose an appropriate word to learn (i.e., not a noun, etc). Since the captions were in English, they were translated through the MyMemory API. Evidently, this app is not really a language learning app since machine translation are not perfect and would not represent how words are used in their context.",
  },
];
